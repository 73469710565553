import * as Sentry from '@sentry/nextjs';

const logger = {
	info(message) {
		Sentry.captureMessage(message, 'info');
	},
	warning(message) {
		Sentry.captureMessage(message, 'warning');
	},
	error(error) {
		Sentry.captureException(error);
	},
	breadcrumb(message, category) {
		Sentry.addBreadcrumb({
			category: category || 'unknown',
			message,
			level: 'info',
		});
	},
};

export default logger;
